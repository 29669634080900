import axios from 'axios';
import AppConsts from '../../appconst';
import { strFormat } from "../../apputils";
import { setLoading } from "./common-slice";

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${process.env.API_BASE_URL}/Product/GetAllProductList`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchProductsByCategoryAndTag = createAsyncThunk(
  "product/fetchProductsByCategoryAndTag",
  async (data, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetAllProducts/CategoryId/${data.SubCategoryId}/Tag/${data.Tag}/${localStorage.getItem('companyId')}`, {
          headers: {
            "Accept":"application/json",
          }
        });
        !response.data.length && thunkApi.dispatch(setLoading(false));
        const outputData = response.data?.map((item) => {
          const productImages = item?.productImageId?.map((imageId) =>
            strFormat(AppConsts.productImagePath, item.id, imageId, localStorage.getItem("companyId"))
          );
          const variants = JSON.parse(item?.variants)
          const variantImages = variants?.map((variant) =>
            strFormat(AppConsts.productVariantImagePath, item.id, variant?.id, localStorage.getItem("companyId"))
          );
          return {
            ...item, variants: variants,
            // image: productImages?.concat(variantImages || []),
            image: variants?.map((e) => {if(e?.imagePath) {return e?.imagePath}}),
          };
        });
        return outputData;
        } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchProductsBySearch = createAsyncThunk(
  "product/fetchProductsBySearch",
  async (data, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetAllProducts/Search/${data}/${localStorage.getItem('companyId')}`, {
          headers: {
            "Accept":"application/json",
          }
        });
        !response.data.length && thunkApi.dispatch(setLoading(false));
        const productData = response.data?.map((item) => {
          const productImages = item?.productImageId?.map((imageId) =>
            strFormat(AppConsts.productImagePath, item.id, imageId, localStorage.getItem("companyId"))
          );
          const variants = JSON.parse(item?.variants)
          const variantImages = variants?.map((variant) =>
            strFormat(AppConsts.productVariantImagePath, item.id, variant?.id, localStorage.getItem("companyId"))
          );
          return {
            ...item, variants: variants,
            image: productImages?.concat(variantImages || []),
          };
        });
        return productData;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchProductsBySubCategory = createAsyncThunk(
  "product/fetchProductsBySubCategory",
  async (SubCategoryId, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetProductsBySubCategory/SubCategoryId/${SubCategoryId}/${localStorage.getItem('companyId')}`, {
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data ? response.data.map( p => ({ ...p, image: [ strFormat(AppConsts.productImagePath, p.id, p.imageId,localStorage.getItem("companyId")), strFormat(AppConsts.productVariantImagePath, p.id, p.variantId,localStorage.getItem("companyId")) ] })) : [];
        // return response.data ? response.data.map( p => ({ ...p, image: [ p.base64Image, p.base64VariantImage ] })) : [];
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async (id, thunkApi) => {
    try {
      thunkApi.dispatch(setLoading(true));
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetProductDetailsById/${id}`, {
          headers: {
            "Accept":"application/json",
          }
        }
      )
      const { variants, ...product } = response.data;
      const { categoryFilterTag, filterTag } = AppConsts;
      let productDetails = {...product, variants: JSON.parse(variants),
        categoryTag: product.tag.split(",").filter(v => v.toLowerCase().includes(categoryFilterTag)).map(v => v.substring(v.lastIndexOf('_')+1)),
        filterTag: product.tag.split(",").filter(v => v.toLowerCase().includes(filterTag)).map(v => v.substring(v.lastIndexOf('_')+1))
      }
      // productDetails && thunkApi.dispatch(setLoading(false));
      return productDetails ? 
      { ...productDetails,
        imagePaths: [ ...productDetails.images.map(image => image) ]
      } : null;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong")
    }
  }
);

export const fetchCategoryList = createAsyncThunk(
  "category/fetchCategorys",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Category/GetAllCategoryList`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchSuggestBySearch = createAsyncThunk(
  "product/fetchSuggestBySearch",
  async (data, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetSuggestProduct/Search/${data}/${localStorage.getItem('companyId')}`, {
          headers: {
            "Accept":"application/json",
          }
        });
        !response.data.length && thunkApi.dispatch(setLoading(false));
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        filterProducts: [],
        filterSuggest:[],
        filterOptions: [],
        loading: false,
        product: null,
        relatedProducts: [],
        categoryList: [],
    },
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },
        setFilterOptions(state, action) {
            state.filterOptions = action.payload;
        },
        resetProductState(state, action) {
            state.products = [];
            state.relatedProducts = [];
            state.filterProducts = [];
            state.filterSuggest=[];
            state.filterOptions = [];
            state.product = null;
            state.categoryList = [];
        }
    },
    extraReducers: {
      [fetchProductsByCategoryAndTag.fulfilled]: (state, action) => {
        state.loading = false
        state.filterProducts = action.payload
      },
      [fetchProductsBySearch.fulfilled]: (state, action) => {
        state.loading = false
        state.filterProducts = action.payload
      },
      [fetchSuggestBySearch.fulfilled]: (state, action) => {
        state.loading = false
        state.filterSuggest = action.payload
      },
      [fetchProductById.fulfilled]: (state, action) => {
        state.loading = false
        state.product = action.payload
      },
      [fetchProductsBySubCategory.fulfilled]: (state, action) => {
        state.loading = false
        state.relatedProducts = action.payload
      },
      [fetchCategoryList.fulfilled]: (state, action) => {
        state.loading = false
        state.categoryList = action.payload
      },
    },
	
});

export const thunks = {
  fetchProductsByCategoryAndTag, fetchProductById, fetchProductsBySubCategory, fetchProductsBySearch,fetchCategoryList,fetchSuggestBySearch
};

export const { setProducts, setFilterOptions, resetProductState } = productSlice.actions;
export default productSlice.reducer;
