import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { fetchPageHeaderDetails } from "../../store/slices/common-slice";
import { generateAlternateLoginId } from "../../apputils";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { menuList } = useSelector((state) => state.common);
  const [visibleTags, setVisibleTags] = useState({});

  const toggleTagsVisibility = (subCategoryId) => {
    setVisibleTags((prevVisibleTags) => ({
      ...prevVisibleTags,
      [subCategoryId]: !prevVisibleTags[subCategoryId],
    }));
  };

  useEffect(() => {
    if (
      !localStorage.getItem("accessToken") &&
      !localStorage.getItem("alternateLoginId")
    ) {
      localStorage.setItem("alternateLoginId", generateAlternateLoginId());
    }
    dispatchPageDependentActions();
  }, []);

  const dispatchPageDependentActions = () => {
    localStorage.getItem("accessToken") && dispatch(fetchPageHeaderDetails());
  };

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          <>
            {menuList.map((m) => (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"} className="main-menu">
                    {m?.name}
                    {sidebarMenu ? (
                      <span>
                        <i className="fa fa-angle-right"></i>
                      </span>
                    ) : (
                      <i className="fa fa-chevron-down" />
                    )}
                  </Link>
                  {m?.subCategories.length && (
                    <ul className="mega-menu mega-menu-padding" style={{maxHeight: "330px", overflowY: "auto", width: '350px',scrollbarWidth: 'thin'}}>
                      <li style={{whiteSpace: 'nowrap'}}>
                        <ul>
                          {m?.subCategories.slice(0, 5).map((sc) => (
                            <>
                              <li className="mega-menu-title" style={{display: 'flex'}}>
                                <span>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/shop/${sc.subCategoryId}`}
                                >
                                  {sc.subCategoryName}
                                </Link>
                                </span>
                                <span>
                                {sc.categoryTags && (
                                  <i
                                    className={`fa ${
                                      visibleTags[sc.subCategoryId]
                                        ? "fa fa-chevron-up"
                                        : "fa fa-chevron-down"
                                    } icon-hover`}
                                    style={{ marginLeft: "6px", marginTop: "9px", cursor: "pointer" }}
                                    onClick={() =>
                                      toggleTagsVisibility(sc.subCategoryId)
                                    }
                                  ></i>
                                )}
                                </span>
                              </li>
                              {visibleTags[sc.subCategoryId] &&
                                sc.categoryTags.split(",").map((ct, index) => (
                                  <li className="tags-hide" key={index}>
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/shop/${
                                        ct.split("_")[1] !== ""
                                          ? sc.subCategoryId +
                                            "/" +
                                            ct
                                              .split("_")[1]
                                              ?.replaceAll(" ", "-")
                                              .trim()
                                          : sc.subCategoryId
                                      }`}
                                    >
                                      {ct.split("_")[1]}
                                    </Link>
                                  </li>
                                ))}
                            </>
                          ))}
                          {m?.subCategories.slice(5, 10).map((sc) => (
                            <>
                              <li className="mega-menu-title" style={{display: 'flex'}}>
                                <span>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/shop/${sc.subCategoryId}`}
                                >
                                  {sc.subCategoryName}
                                </Link>
                                </span>
                                <span>
                                {sc.categoryTags && (
                                  <i
                                    className={`fa ${
                                      visibleTags[sc.subCategoryId]
                                        ?"fa fa-chevron-up"
                                        : "fa fa-chevron-down"
                                    } icon-hover`}
                                    style={{ marginLeft: "6px", marginTop: "9px", cursor: "pointer"}}
                                    onClick={() =>
                                      toggleTagsVisibility(sc.subCategoryId)
                                    }
                                  ></i>
                                )}
                                </span>
                              </li>
                              {visibleTags[sc.subCategoryId] &&
                                sc.categoryTags.split(",").map((ct, index) => (
                                  <li className="tags-hide" key={index}>
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/shop/${
                                        ct.split("_")[1] !== ""
                                          ? sc.subCategoryId +
                                            "/" +
                                            ct
                                              .split("_")[1]
                                              ?.replaceAll(" ", "-")
                                              .trim()
                                          : sc.subCategoryId
                                      }`}
                                    >
                                      {ct.split("_")[1]}
                                    </Link>
                                  </li>
                                ))}
                            </>
                          ))}
                          {m?.subCategories.slice(10, 15).map((sc) => (
                            <>
                              <li className="mega-menu-title" style={{display: 'flex'}}>
                              <span>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/shop/${sc.subCategoryId}`}
                                >
                                  {sc.subCategoryName}
                                </Link>
                                </span>
                                <span>
                                {sc.categoryTags && (
                                  <i
                                    className={`fa ${
                                      visibleTags[sc.subCategoryId]
                                        ? "fa fa-chevron-up"
                                        : "fa fa-chevron-down"
                                    } icon-hover`}
                                    style={{ marginLeft: "6px", marginTop: "9px", cursor: "pointer" }}
                                    onClick={() =>
                                      toggleTagsVisibility(sc.subCategoryId)
                                    }
                                  ></i>
                                )}
                                </span>
                              </li>
                              {visibleTags[sc.subCategoryId] &&
                                sc.categoryTags.split(",").map((ct, index) => (
                                  <li className="tags-hide" key={index}>
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/shop/${
                                        ct.split("_")[1] !== ""
                                          ? sc.subCategoryId +
                                            "/" +
                                            ct
                                              .split("_")[1]
                                              ?.replaceAll(" ", "-")
                                              .trim()
                                          : sc.subCategoryId
                                      }`}
                                    >
                                      {ct.split("_")[1]}
                                    </Link>
                                  </li>
                                ))}
                            </>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  )}
                </li>
              </>
            ))}
          </>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
