
import axios from 'axios';
import AppConsts from '../../appconst';
import cogoToast from "cogo-toast";
import { getLoggedInUserId, readToken } from '../../apputils';


const { createSlice , createAsyncThunk} = require('@reduxjs/toolkit');

export const fetchAllAddressByUserId = createAsyncThunk(
    "fetchAllAddressByUserId",
    async (_, thunkApi) => {
      try {
        const response = await axios.get(
          `${AppConsts.apiBaseUrl}/Address/GetAddressesByUser`,
           {
            headers: {
              "Accept":"application/json",
              "Authorization": 'Bearer ' + readToken(),
            }
          });
          return response.data;
        
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );

  export const saveAddress = createAsyncThunk(
    "saveAddress",
    async (data, thunkApi) => {
      try {
        const response = await axios.post(
          `${AppConsts.apiBaseUrl}/Address/Save`,
           {...data},
           {
            headers: {
              "Accept":"application/json",
              "Authorization": 'Bearer ' + readToken(),
            }
          });
          return response.data;
        
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );

  export const updateAddress = createAsyncThunk(
    "updateAddress",
    async (data, thunkApi) => {
      try {
        const response = await axios.put(
          `${AppConsts.apiBaseUrl}/Address/Update`,
           {...data},
           {
            headers: {
              "Accept":"application/json",
              "Authorization": 'Bearer ' + readToken(),
            }
          });
          return response.data;
        
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );

  export const deleteAddress = createAsyncThunk(
    "deleteAddress",
    async (id, thunkApi) => {
      try {
        const response = await axios.put(
          `${AppConsts.apiBaseUrl}/Address/Delete/${id}`,
           {
            headers: {
              "Accept":"application/json",
              "Authorization": 'Bearer ' + readToken(),
            }
          });
          return response.data;
        
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );

  export const setAsDefault = createAsyncThunk(
    "setAsDefault",
    async (id, thunkApi) => {
      try {
        const response = await axios.put(
          `${AppConsts.apiBaseUrl}/Address/UpdateDefault/${id}`,
           {
            headers: {
              "Accept":"application/json",
              "Authorization": 'Bearer ' + readToken(),
            }
          });
          return response.data;
        
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );

const addressSlice = createSlice({
    name: "address",
    initialState: {
        addressList: [],
    },
    reducers: {
      setAddressList(state, action) {
            state.addressList = action.payload;
        }
    },
    extraReducers:{
        [fetchAllAddressByUserId.fulfilled]: (state, action) => {
          state.loading = false
          state.addressList = action.payload
        },
        [deleteAddress.fulfilled]: (state, action) => {
          state.loading = false
          cogoToast.error("Address removed from your address book", {position: "top-center"});
        },
        [saveAddress.fulfilled]: (state, action) => {
          state.loading = false
          cogoToast.success("Address added to your address book", {position: "top-center"});
        },
        [setAsDefault.fulfilled]: (state, action) => {
          state.loading = false
          cogoToast.success("Default address updated successfully", {position: "top-center"});
        },
        [updateAddress.fulfilled]: (state, action) => {
          state.loading = false
          cogoToast.success("Address updated successfully", {position: "top-center"});
        }
    }
});


export const thunks = {
    fetchAllAddressByUserId ,deleteAddress,saveAddress,updateAddress
  }; 
  
   export const { setAddressList} = addressSlice.actions;
  export default addressSlice.reducer;