import axios from 'axios';
import AppConsts from '../../appconst';
import { strFormat, getLoggedInUserId, readToken } from "../../apputils";


const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const fetchMenuByCategoryTag = createAsyncThunk(
  "product/fetchMenuByCategoryTag",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Category/GetMenuCategories`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
                  }
      });
      let menuList = response.data;
      menuList = [...new Set(menuList.map((item) => item.categoryId))].map((cId) => {
          const subCatList = menuList.filter(sc => sc.categoryId === cId);
          return { id: cId, name: subCatList[0].categoryName, pcImage: subCatList[0].categoryImageUrl, subCategories: [...menuList.filter(sc => sc.categoryId === cId)]};
        })
      return menuList;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchPopularCategories = createAsyncThunk(
  "fetchPopularCategories",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/PopularCategory/GetAllPopularCategoryList`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
          }
        });
        let popularCategories = response.data;
        popularCategories = [...new Set(popularCategories.map(Item =>(Item.id)))].map((cId=>{
            const Product = popularCategories.filter(t => t.id === cId)
            return {categoryId:cId,categoryName:Product[0].categoryName,productDetails:[...Product.filter(c => c.id === cId).map((item) => ({ ...item, image: [strFormat(AppConsts.productImagePath, item.productId, 1,localStorage.getItem("companyId")), strFormat(AppConsts.productVariantImagePath, item.productId, item.variantId,localStorage.getItem("companyId"))] }))]}
        }));
        return popularCategories;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchMasterDataByType = createAsyncThunk(
  "fetchMasterDataByType",
  async (type, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/MasterData/GetMasterData/Type/${type}`, {
          headers: {
            "Accept":"application/json",
                  }
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchAllMasterData = createAsyncThunk(
  "fetchAllMasterData",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/MasterData/GetAllMasterDataList`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
                  }
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

// export const fetchHomeCategoryProducts = createAsyncThunk(
//   "fetchHomeCategoryProducts",
//   async (categoryId, thunkApi) => {
//     try {
//       const response = await axios.get(
//         `${AppConsts.apiBaseUrl}/Product/GetHomeCategoryProductListById/${categoryId}`, {
//           params:{
//             CompanyId: localStorage.getItem('companyId'),
//            },
//           headers: {
//             "Accept":"application/json",
//           }
//         });
//         // let categoryProducts = response.data;
//         // categoryProducts = [...new Set(categoryProducts.map(Item =>(Item.id)))].map((cId=>{
//         //     const Product = categoryProducts.filter(t => t.id === cId)
//         //     return {categoryId:cId,categoryName:Product[0].categoryName,productDetails:[...Product.filter(c => c.id === cId).map((item) => ({ ...item, image: [strFormat(AppConsts.productImagePath, item.productId, item.imageId,localStorage.getItem("companyId")), strFormat(AppConsts.productVariantImagePath, item.productId, item.variantId,localStorage.getItem("companyId"))] }))]}
//         // }));

//       //   let categoryProducts = response.data.map((sc=>{
//       //     return { categoryId: sc[0].id, categoryName: sc[0].categoryName, productDetails: sc.productDetails.map((item) => ({ ...item, image: [strFormat(AppConsts.productImagePath, item.productId, item.imageId,localStorage.getItem("companyId")), strFormat(AppConsts.productVariantImagePath, item.productId, item.variantId,localStorage.getItem("companyId"))] }))]}
//       // }));
//       let categoryProducts = response.data;
//       // categoryProducts = { categoryId: categoryProducts[0].id, categoryName: categoryProducts[0].categoryName, productDetails: categoryProducts.map((item) => ({ ...item, image: [strFormat(AppConsts.productImagePath, item.productId, item.imageId,localStorage.getItem("companyId")), strFormat(AppConsts.productVariantImagePath, item.productId, item.variantId,localStorage.getItem("companyId"))] })) }
//         // thunkApi.dispatch(setLoading(false));
//         categoryProducts = {categoryId: categoryProducts[0].id, categoryName: categoryProducts[0].categoryName,
//           productDetails: 
//           response.data?.map((item) => {
//            const productImages = item?.productImageId?.map((imageId) =>
//              strFormat(AppConsts.productImagePath, item?.productId, imageId, localStorage.getItem("companyId"))
//            );
//            const variants = JSON.parse(item?.variants);
//            const variantImages = variants?.map((variant) =>
//              strFormat(AppConsts.productVariantImagePath, item?.productId, variant?.id, localStorage.getItem("companyId"))
//            );
//            return {...item, variants: variants, image : productImages?.concat(variantImages || [])}
//          }) }
//         return categoryProducts;
//     } catch (error) {
//       return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
//     }
//   }
// );

// export const fetchHomeFeatureProducts = createAsyncThunk(
//   "fetchHomeFeatureProducts",
//   async (_featureProducts, thunkApi) => {
//     try {
//       const response = await axios.get(
//         `${AppConsts.apiBaseUrl}/Product/GetHomeFeatureProductsList`, {
//           params:{
//             FeatureProducts: _featureProducts,
//             CompanyId: localStorage.getItem('companyId'),
//            },
//           headers: {
//             "Accept":"application/json",
//           }
//         });
//       let featureProducts = response.data;
//       featureProducts = {categoryId: featureProducts[0].id, categoryName: featureProducts[0].categoryName,
//           productDetails: 
//           response.data?.map((item) => {
//            const productImages = item?.productImageId?.map((imageId) =>
//              strFormat(AppConsts.productImagePath, item?.productId, imageId, localStorage.getItem("companyId"))
//            );
//            const variants = JSON.parse(item?.variants);
//            const variantImages = variants?.map((variant) =>
//              strFormat(AppConsts.productVariantImagePath, item?.productId, variant?.id, localStorage.getItem("companyId"))
//            );
//            return {...item, variants: variants, image : productImages?.concat(variantImages || [])}
//          }) }
//         return featureProducts;
//     } catch (error) {
//       return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
//     }
//   }
// );

export const fetchPageHeaderDetails = createAsyncThunk(
  "fetchPageHeaderDetails",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/GetPageHeaderDetails/UserId/${getLoggedInUserId()}`, {
          headers: {
            "Accept":"application/json",
                  }
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
)

export const fetchAllPaymentProvider = createAsyncThunk(
  "fetchAllPaymentProvider",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/PaymentProviders/GetActivePaymentProviders`,
        {
          params: {
            CompanyId: localStorage.getItem("companyId"),
          },
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const getShippingProductsPrice = createAsyncThunk(
  "getAllShippingProductsPrice",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/ShippingProfile/GetShippingProductsPrice`,
        data,
        {
          headers: {
            "Accept": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

export const orderList = createAsyncThunk(
  "getOrderList",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Order/GetOrdersList`,
        {
          headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + readToken(),
          },
        });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

export const fetchSubCategoryList = createAsyncThunk(
  "subCategory/fetchSubCategorys",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/SubCategory/GetAllSubCategoryList`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchSubCategoryListById = createAsyncThunk(
  "subCategory/fetchSubCategory",
  async (id, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/SubCategory/GetSubCategoryDetailsById`,
        {
          params: {
            Id: id
          },
          headers: {
            "Accept": 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchAllDeliveryZone = createAsyncThunk(
  'fetchAllDeliveryZone',
  async (_, thunkApi) => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/DeliveryZone/GetAllDeliveryZoneList`, {
          params: {
            CompanyId: companyId,
          },
          headers: {
            "Accept": 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  }
);
const commonSlice = createSlice({
    name: 'common',
    initialState: {
        menuList: [],
        popularCategories: [],
        homeCategoryProducts: [],
        homeFeatureProducts: [],
        subCategoryLists: [],
        SubCategoryList: null,
        addressType: [],
        masterData: [],
        deliveryZone:[],
        paymentProviders: [],
        loading: false,
        pageHeaderDetails: null,
        blockAction: false,
    },
    reducers: {
        setLoading(state, action) {
          state.loading = action.payload;
        },
        resetState(state) {
          state.pageHeaderDetails = [];
        },

        // setHomeCategoryProducts(state, action){
        //   state.homeCategoryProducts = action.payload;
        // },

        // setHomeFeatureProducts(state, action){
        //   state.homeFeatureProducts = action.payload;
        // },

        setSubCategoryLists(state, action){
          state.subCategoryLists = action.payload;
        },
        setBlockAction(state, action) {
          state.blockAction = action.payload;
        }
    },
    extraReducers: {
      [fetchMenuByCategoryTag.fulfilled]: (state, action) => {
        state.loading = false
        state.menuList = action.payload
      },
      [fetchPopularCategories.fulfilled]: (state, action) => {
        state.loading = false
        state.popularCategories = action.payload
      },
      [fetchMasterDataByType.fulfilled]: (state, action) => {
        state.loading = false
        state.addressType = action.payload
      },
      [fetchAllMasterData.fulfilled]: (state, action) => {
        state.loading = false
        state.masterData = action.payload
      },
      [fetchSubCategoryListById.fulfilled]: (state, action) => {
        state.loading = false;
        state.SubCategoryList = action.payload;
      },
      [fetchSubCategoryListById.pending]: (state) => {
        state.loading = true;
      },
      [fetchSubCategoryListById.rejected]: (state) => {
        state.loading = false;
      },

      // [fetchHomeCategoryProducts.fulfilled]: (state, action) => {
      //   state.loading = false
      //   state.homeCategoryProducts.push(action.payload);
      // },
      
      // [fetchHomeFeatureProducts.fulfilled]: (state, action) => {
      //   state.loading = false
      //   state.homeFeatureProducts=action.payload;
      // },

      [fetchSubCategoryList.fulfilled]: (state, action) => {
        state.loading = false
        state.subCategoryLists=action.payload;
      },
      [fetchPageHeaderDetails.fulfilled]: (state, action) => {
        state.loading = false
        state.pageHeaderDetails = action.payload
      },
      [fetchAllPaymentProvider.fulfilled]: (state, action) => {
        state.loading = false;
        state.paymentProviders = action.payload;
      },  
      [fetchAllDeliveryZone.fulfilled]: (state, action) => {
        state.loading = false
        state.deliveryZone = action.payload
      },
    },
});

export const thunks = {
  fetchMenuByCategoryTag,
  fetchPopularCategories,
  fetchMasterDataByType,
  fetchAllMasterData,
  fetchAllDeliveryZone,
  fetchPageHeaderDetails,
  fetchAllPaymentProvider,
  orderList,
  fetchSubCategoryListById
};
export const { setLoading, resetState, setSubCategoryLists} = commonSlice.actions;
export default commonSlice.reducer;
