import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const FooterCopyright = ({ spaceBottomClass, colorClass, footerCompanyList }) => {
  // const { companyLogoImage } = useSelector(state => state.auth);
  // const imageUrlLogo = `data:image/png;base64,${companyLogoImage.imageBase64}`;
  const { t } = useTranslation();
  
  return (
    <div className={clsx("copyright", spaceBottomClass, colorClass)}>
      <div className="footer-logo">
        <Link to={process.env.PUBLIC_URL + "/"}>
        {footerCompanyList?.companyLogo ? (
          <>
            <img
              style={{width: '150px', marginBottom: '20px'}}
              src={footerCompanyList?.companyLogo}
              alt="Company Logo"
            />
            </>
        ) : (
          <>
           <img
              style={{width: '200px', marginBottom: '20px'}}  
              src= "https://zemez.io/html/wp-content/uploads/sites/9/2017/10/logo.png"
              alt="Company Logo"
            />
          </> 
        )} 
        </Link>
      </div>
      <div class="footer-text">
                <p>{footerCompanyList?.aboutUs}</p>
      </div>
       <br></br>
      <p>  
        &copy; {new Date().getFullYear()}{" "}
        <Link
          href="https://hasthemes.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          {footerCompanyList?.companyName}
        </Link>
        .<br /> {t("all_rights_reserved")}
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string
};

export default FooterCopyright;
