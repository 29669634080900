import AppConsts from './appconst'

export const strFormat = (str, ...args) => args.reduce((s, v) => s.replace('%s', v), str);

export const readToken = () => localStorage.getItem('accessToken') || undefined;

export const generateAlternateLoginId = () => Math.random().toString(36).substr(2, 9) + '_' + Date.now();

export const getLoggedInUserId = 
() => localStorage.getItem("userId") ? localStorage.getItem("userId") : localStorage.getItem("alternateLoginId");

export const stringEllipsis = (text, maxLength) => text.length > maxLength ? `${text.slice(0, maxLength)}...` : text.padEnd(maxLength, ' ');

export const sanitizeBase64Image = (base64Image) => base64Image.startsWith("data:") ? base64Image : `${AppConsts.base64ImagePrefix}${base64Image}`;

export const handleAddItemAction = (dispatch, actionType, actionFunction, blockAction, setBlockAction, ...actionArgs) => {
    if (blockAction[actionType]) {
      return; // Action is blocked, do not proceed
    }
  
    setBlockAction({ ...blockAction, [actionType]: true });

    dispatch(actionFunction(...actionArgs)).then((res) => {
      if(res.error && res.error.message === "Rejected") {
        setBlockAction({ ...blockAction, [actionType]: false });
      }
    });
  };