import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

const MobileNavMenu = () => {
  const { t } = useTranslation();
  
  const { menuList} = useSelector(state => state.common);

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{t("home")}</Link>
        </li>
            {menuList.map((m) => (
              <li className="menu-item-has-children" key={m.id}>
                <Link to={process.env.PUBLIC_URL + "/"}>
                  {m?.name}
                </Link>
                {m?.subCategories.length > 0 && (
                  <ul className="sub-menu">
                    {m.subCategories.slice(0, 15).map((sc) => (
                      <li className="menu-item-has-children" key={sc.subCategoryId}>
                        <Link to={`${process.env.PUBLIC_URL}/shop/${sc.subCategoryId}`}>
                          {sc.subCategoryName}
                        </Link>
                        {sc.categoryTags && (
                          <ul className="sub-menu">
                            {sc.categoryTags.split(',').map((ct, index) => {
                              const tagId = ct.split('_')[1]?.trim();
                              return (
                                <li key={index}>
                                  <Link to={`${process.env.PUBLIC_URL}/shop/${tagId ? `${sc.subCategoryId}/${tagId}` : sc.subCategoryId}`}>
                                    {tagId}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{t("Account")}</Link>
          <ul className="sub-menu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/my-account"}>
                {t("my_account")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                {t("login_register")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/about"}>
                {t("about_us")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/contact"}>
                {t("contact_us")}
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
            {t("blog")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
