import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import AppConsts from '../../appconst';
import { setLoading } from './common-slice';

export const fetchAllBrandList = createAsyncThunk(
'fetchAllBrandList',
async (_, thunkApi) => {
    try{
        const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Brand/GetAllBrandList`, {
          params:{
           CompanyId: localStorage.getItem('companyId'),
          },
        headers: {
            "Accept":"application/json",
        }
        });
        thunkApi.dispatch(setLoading(false));
        return response.data;
    }
    catch(error)
    {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
    
})

const promotionSlice = createSlice({
  name: "brand",
  initialState: {
    brandList: [],
  },
  reducers: {
    setPromotionList(state,action) {
      state.brandList = action.payload;
    },
  },
  extraReducers: {
    [fetchAllBrandList.fulfilled]:(state,action) => {
        state.loading = false
        state.brandList = action.payload;
    }
  }
})

export const thunks = {
    fetchAllBrandList
};

export const { setPromotionList } = promotionSlice.actions;
export default promotionSlice.reducer