import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import AppConsts from '../../appconst';
import { setLoading } from './common-slice';

export const fetchAllPromotionBannerList = createAsyncThunk(
'fetchAllPromotionBannerList',
async (_, thunkApi) => {
    try{
        
        const response = await axios.get(
        `${AppConsts.apiBaseUrl}/PromotionBanner/GetAllPromotionBannerList`, {
          params:{
           CompanyId: localStorage.getItem('companyId'),
          },
        headers: {
            "Accept":"application/json",
        }
        });
        thunkApi.dispatch(setLoading(false));
        return response.data;
    }
    catch(error)
    {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
    
})

const promotionSlice = createSlice({
  name: "promotionbanner",
  initialState: {
    promotionBannerList: [],
  },
  reducers: {
  },
  extraReducers: {
    [fetchAllPromotionBannerList.fulfilled]:(state,action) => {
        state.loading = false
        state.promotionBannerList = action.payload;
    },
  }
})

export const thunks = {
  fetchAllPromotionBannerList
};

export default promotionSlice.reducer