import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import AppConsts from '../../appconst';
import { setLoading } from './common-slice';

export const fetchAllBlogList = createAsyncThunk(
'fetchAllBlogList',
async (_, thunkApi) => {
    try{
        const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Blog/GetAllBlogList`, {
          params:{
           CompanyId: localStorage.getItem('companyId'),
          },
        headers: {
            "Accept":"application/json",
        }
        });
        thunkApi.dispatch(setLoading(false));
        return response.data;
    }
    catch(error)
    {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
    
})

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    blogList: [],
  },
  reducers: {
    setBlogList(state,action) {
      state.blogList = action.payload;
    },
  },
  extraReducers: {
    [fetchAllBlogList.fulfilled]:(state,action) => {
        state.loading = false
        state.blogList = action.payload;
    }
  }
})

export const thunks = {
    fetchAllBlogList
};

export const { setBlogList } = blogSlice.actions;
export default blogSlice.reducer