import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import AppConsts from '../../appconst';
import { setLoading } from './common-slice';

export const fetchAllPromotionList = createAsyncThunk(
'fetchAllPromotionList',
async (_, thunkApi) => {
    try{
        
        const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Promotion/GetAllPromotionList`, {
          params:{
           CompanyId: localStorage.getItem('companyId'),
          },
        headers: {
            "Accept":"application/json",
        }
        });
        thunkApi.dispatch(setLoading(false));
        return response.data;
    }
    catch(error)
    {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
    
})

const promotionSlice = createSlice({
  name: "promotion",
  initialState: {
    promotionList: [],
  },
  reducers: {
    setPromotionList(state,action) {
      state.promotionList = action.payload;
    },
  },
  extraReducers: {
    [fetchAllPromotionList.fulfilled]:(state,action) => {
        state.loading = false
        state.promotionList = action.payload;
    }
  }
})

export const thunks = {
    fetchAllPromotionList
};

export const { setPromotionList } = promotionSlice.actions;
export default promotionSlice.reducer