import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import AppConsts from '../appconst';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: AppConsts.apiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
});

// Define a default bypass token check flag
axiosInstance.defaults.headers.common['X-Bypass-Token-Check'] = false;

// Add a request interceptor
axiosInstance.interceptors.request.use(async (req) => {

  // Check if the request should bypass the token check
  if (req.headers['X-Bypass-Token-Check']) {
    return req; // Bypass the token check for this request
  }

  const accessToken = localStorage.getItem('accessToken') || null;

  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }

  const tokenData = jwt_decode(accessToken);
  const isExpired = dayjs.unix(tokenData.exp).diff(dayjs()) < 1;

  if (!isExpired) return req;

  const refreshToken = localStorage.getItem('refreshToken');

  const response = await axios.post(
    `${AppConsts.apiBaseUrl}/Auth/refresh`,
    {
      accessToken,
      refreshToken,
      userId: localStorage.getItem('userId'),
      companyId: localStorage.getItem('companyId'),
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  req.headers.Authorization = `Bearer ${response.data.accessToken}`;
  localStorage.setItem('accessToken', response.data.accessToken);
  localStorage.setItem('refreshToken', response.data.refreshToken);

  return req;
});

export default axiosInstance;
