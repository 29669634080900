import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Table, Badge, Button } from 'antd';
import { fetchAllMasterData } from "../../store/slices/common-slice";
import moment from 'moment';
import { useEffect } from "react";
import AppConsts from "../../appconst";
import LayoutOne from "../../layouts/LayoutOne";
import { fetchOrderDetails } from "../../store/slices/checkout-slice";
import { jsPDF } from "jspdf";
import { fulfillmentInvoice } from "../../store/slices/fullfilment-slice";
import 'jspdf-autotable';
import { toWords } from 'number-to-words';
import { DownloadOutlined } from "@ant-design/icons"; 

const OrderDetails = () => {

    const dispatch = useDispatch();
    let { id } = useParams();

    const { orderDetails } = useSelector((state) => state.checkout);
    const { masterData } = useSelector((state) => state.common);
    const { invoiceDetails } = useSelector((state) => state.fulfillment);
    const orderStatus = orderDetails?.invoiceStatus;
    const orderNumber = orderDetails?.orderNo ? orderDetails?.orderNo : "Invoice";

    const columns = [
        { title: 'Product', dataIndex: 'productName' },
        { title: 'Price', dataIndex: 'salesRate' },
        { title: 'Quantity', dataIndex: 'quantity' },
        { title: 'Total', dataIndex: 'orderTotal', render: (text, record) => <span>{record.salesRate * record.quantity}</span> },
    ];

    useEffect(() => {
        dispatch(fetchAllMasterData());
        dispatch(fetchOrderDetails(id));
    }, [id])

    const GeneratePDF = (invoiceDetails) => {
        const doc = new jsPDF('p', 'mm', 'a4');

        const outerMargin = 10;
        const innerMargin = 5;
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const contentWidth = pageWidth - 2 * outerMargin;
        const contentHeight = pageHeight - 2 * outerMargin;

        const groupedProducts = invoiceDetails.reduce((acc, product) => {
            if (!acc[product.fulfillmentReference]) {
                acc[product.fulfillmentReference] = [];
            }
            acc[product.fulfillmentReference].push(product);
            return acc;
        }, {});

        const addPageWithProducts = (products, isFirstPage) => {
            const invoiceDetails = products[0];

            doc.rect(outerMargin, outerMargin, contentWidth, contentHeight);

            doc.setFontSize(10);
            doc.setLineWidth(0.1);

            const headingText = 'Invoice Tax';
            const headingX = (pageWidth - doc.getTextWidth(headingText)) / 2;
            doc.text(headingText, headingX, outerMargin + innerMargin + 5);

            doc.text('E. &0.E.', outerMargin + innerMargin, pageHeight - outerMargin - innerMargin - 5);

            const centeredText = 'The goods sold are intended for end user consumption, not for resale';
            const centeredX = (pageWidth - doc.getTextWidth(centeredText)) / 2;
            doc.text(centeredText, centeredX, pageHeight - outerMargin - innerMargin - 5);

            const boxHeightTop = 30;
            doc.rect(outerMargin + innerMargin, outerMargin + innerMargin + 15, (contentWidth / 2) - innerMargin, boxHeightTop);
            doc.rect(outerMargin + contentWidth / 2 + innerMargin / 2, outerMargin + innerMargin + 15, (contentWidth / 2) - innerMargin, boxHeightTop);

            const firstBoxX = outerMargin + innerMargin + 2;
            const firstBoxY = outerMargin + innerMargin + 22;

            doc.text(`Sold By:`, firstBoxX, firstBoxY);
            doc.text(`${invoiceDetails.name || ''}`, firstBoxX, firstBoxY + 4);
            doc.text(`${invoiceDetails.address || ''}`, firstBoxX, firstBoxY + 8);
            doc.text(`${invoiceDetails.state || ''}`, firstBoxX, firstBoxY + 12);
            doc.text(`${invoiceDetails.city || ''}`, firstBoxX, firstBoxY + 16);
            doc.text(`${invoiceDetails.pinCode || ''}`, firstBoxX, firstBoxY + 20);

            const rightBoxX = outerMargin + contentWidth / 2 + innerMargin + 2;
            let currentY = outerMargin + innerMargin + 22;

            doc.text(`Order through:`, rightBoxX, currentY);
            currentY += 4;
            doc.text(`${invoiceDetails.companyName || ''}`, rightBoxX, currentY);
            currentY += 4;
            doc.text(`${invoiceDetails.comAddress || ''}`, rightBoxX, currentY);
            currentY += 4;
            doc.text(`${invoiceDetails.comCity || ''}`, rightBoxX, currentY);
            currentY += 4;
            doc.text(`${invoiceDetails.email || ''}`, rightBoxX, currentY);


            const boxHeightSecondRow = 30;
            const startY = outerMargin + innerMargin + 15 + boxHeightTop + innerMargin;

            for (let i = 0; i < 3; i++) {
                doc.rect(outerMargin + innerMargin + i * ((contentWidth / 3) - innerMargin / 3), startY, (contentWidth / 3) - innerMargin / 3, boxHeightSecondRow);
            }

            doc.setFontSize(10);
            let orderDetailsX = outerMargin + innerMargin + 2;
            let orderDetailsY = startY + 10;
            doc.text(`Order No: ${invoiceDetails.orderNo || ''}`, orderDetailsX, orderDetailsY);
            doc.text(`Order Date: ${invoiceDetails.orderDate || ''}`, orderDetailsX, orderDetailsY + 4);
            doc.text(`Quantity: ${invoiceDetails.quantity || ''}`, orderDetailsX, orderDetailsY + 8);

            let billingAddressX = outerMargin + contentWidth / 3 + innerMargin + 2;
            let billingAddressY = startY + 10;

            doc.text(`Billing Address:`, billingAddressX, billingAddressY);
            const billingAddress = orderDetails.billingAddress || {};

            doc.text(`${billingAddress.name || ''}`, billingAddressX, billingAddressY + 4);
            doc.text(`${billingAddress.addressLine || ''}`, billingAddressX, billingAddressY + 8);
            doc.text(`${billingAddress.area || ''}`, billingAddressX, billingAddressY + 12);
            doc.text(`${billingAddress.state || ''}`, billingAddressX, billingAddressY + 16);

            let shippingAddressX = outerMargin + 2 * (contentWidth / 3) + innerMargin + 2;
            let shippingAddressY = startY + 10;

            doc.text(`Shipping Address:`, shippingAddressX, shippingAddressY);
            const shippingAddress = orderDetails.shippingAddress || {};
            doc.text(`${shippingAddress.name || ''}`, shippingAddressX, shippingAddressY + 4);
            doc.text(`${shippingAddress.addressLine || ''}`, shippingAddressX, shippingAddressY + 8);
            doc.text(`${shippingAddress.landmark || ''}`, shippingAddressX, shippingAddressY + 12);
            doc.text(`${shippingAddress.pincode || ''}`, shippingAddressX, shippingAddressY + 16);

            const tableColumn = [
                { header: "S.No.", dataKey: "sno" },
                { header: "Product Details", dataKey: "productName" },
                { header: "Price", dataKey: "salesRate" },
                { header: "Qty", dataKey: "quantity" },
                { header: "Total", dataKey: "quantityTotal" },
            ];

            const tableRows = products.map((product, index) => ({
                sno: index + 1,
                productDetails: `${product.productName}\n${product.variantName || ''}\n${product.sku || ''}`,  // Adding line breaks
                salesRate: product.salesRate,
                quantity: product.quantity,
                quantityTotal: product.quantityTotal,
            }));
            const Discount = products.reduce((total, product) => total + (product.discount || 0), 0);
            const ShipmentCharges = products.reduce((total, product) => total + (product.shipmentCharges || 0), 0);
            const COD = products.reduce((total, product) => total + (product.cod || 0), 0);
            const orderTotal = products.reduce((total, product) => total + (product.orderTotal || 0), 0);

            function numberToWords(number) {
                return toWords(number);
            }

            if (isFirstPage) {
                tableRows.push({
                    sno: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    productName: { content: 'Discount:', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center', fontStyle: 'bold' } },
                    quantity: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    salesRate: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    quantityTotal: { content: ` ${Discount}`, styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } }, // Dynamic discount
                });

                tableRows.push({
                    sno: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    productName: { content: 'Shipment Charges:', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center', fontStyle: 'bold' } },
                    quantity: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    salesRate: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    quantityTotal: { content: ` ${ShipmentCharges}`, styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } }, // Dynamic shipment charges
                });

                tableRows.push({
                    sno: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    productName: { content: 'COD:', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center', fontStyle: 'bold' } },
                    quantity: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    salesRate: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                    quantityTotal: { content: ` ${COD}`, styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } }, // Dynamic COD
                });
            }

            tableRows.push({
                sno: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                productName: { content: 'Total:', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center', fontStyle: 'bold' } },
                quantity: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                salesRate: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } },
                quantityTotal: { content: ` ${(!isFirstPage) && COD ? orderTotal - COD : orderTotal}`, styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center' } }, // Dynamic total
            });

            tableRows.push({
                sno: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 } } },
                productName: { content: `Amount In Words: ${numberToWords(orderTotal)}`, styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 }, halign: 'center', fontStyle: 'bold' } }, // Center the text
                quantity: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 } } },
                salesRate: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 } } },
                quantityTotal: { content: '', styles: { lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 } } },
            });

            // doc.setFontSize(16); // Set font size
            // doc.setFont('helvetica', 'bold'); // Set font style
            // doc.text('Invoice Heading', 105, 20, { align: 'center' });

            // const doc = new jsPDF();

            // // Base64-encoded image (replace with your Base64 string)
            // const imgData = 'https://usm-production.s3.us-east-2.amazonaws.com/Companyimage/20240831054911339_usm-logo.webp'; // Replace with your full Base64 string
            
            // doc.setFontSize(40);
            // doc.text(30, 20, 'Invoice');
            
            // // Add Base64 image to the PDF
            // doc.addImage(imgData, 'JPEG', 15, 40, 180, 160); // Adjust position and size as needed
            
            // doc.save('output.pdf');
            
            doc.autoTable({
                head: [tableColumn.map(col => col.header)],
                body: tableRows.map(row => Object.values(row)),
                startY: startY + boxHeightSecondRow + innerMargin,
                theme: 'plain',
                styles: {
                    fontSize: 9,
                    cellPadding: 2,
                    lineWidth: 0, 
                    lineColor: [0, 0, 0], 
                },
                headStyles: {
                    textColor: [0, 0, 0],
                    fontSize: 9,
                    lineWidth: 0.1, 
                    halign: 'center',
                },
                bodyStyles: {
                    textColor: [0, 0, 0],
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0],
                },
                margin: { left: outerMargin + innerMargin, right: outerMargin + innerMargin },
                tableLineWidth: 0.1, 
                tableLineColor: [0, 0, 0], 
            });
        };

        Object.keys(groupedProducts).forEach((fulfillmentReference, index) => {
            if (index > 0) {
                doc.addPage();
            }
            addPageWithProducts(groupedProducts[fulfillmentReference], index === 0);
        });

        doc.save(`Invoice_${orderNumber}.pdf`);
    };
    
    const handleGeneratePDF = async () => {
        await dispatch(fulfillmentInvoice(orderDetails.orderNo));

        if (invoiceDetails) {
            GeneratePDF(invoiceDetails);
        }
    };

    return (
        <>
            <Fragment>
                <LayoutOne headerTop="visible">
                    <div className="cart-main-area pt-90 pb-100">
                        <div className="container">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Button style={{ backgroundColor: 'black', color: 'white' }}>
                                    <span><i className="fa fa-angle-left"></i></span>
                                    <Link to={process.env.PUBLIC_URL + "/order-history"} style={{ textDecoration: 'none', color: 'white' }}>
                                        Back to orders
                                    </Link>
                                </Button>
                                {orderStatus === 'AT' && (
                                    <Button
                                        type="primary"
                                        icon={<DownloadOutlined />}
                                        onClick={handleGeneratePDF}
                                    >
                                        Invoice
                                    </Button>
                                )}
                            </div>
                            <Fragment>
                                <div className="row" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                    <div className="col-12">
                                        <div className="table-content table-responsive">
                                            <Table className="auth-ant-table" style={{ marginTop: '10px' }}
                                                columns={columns}
                                                dataSource={orderDetails?.productDetails}
                                                rowKey="id"
                                                pagination={false}
                                                summary={() => {
                                                    return (
                                                        <>
                                                            <tr className="footer-td">
                                                                <td colSpan={3}>Sub Total</td>
                                                                <td>{orderDetails?.orderSubTotal}</td>
                                                            </tr>
                                                            <tr className="footer-td">
                                                                <td colSpan={3}>Discount</td>
                                                                <td>{orderDetails?.discount}</td>
                                                            </tr>
                                                            <tr className="footer-td">
                                                                <td colSpan={3}>Shipping Fees</td>
                                                                <td>{orderDetails?.shipmentCharges}</td>
                                                            </tr>
                                                            {orderDetails?.paymentType !== 'O' && (
                                                                <tr className="footer-td">
                                                                    <td colSpan={3}>COD</td>
                                                                    <td>{orderDetails?.cod}</td>
                                                                </tr>
                                                            )}
                                                            <tr className="footer-td">
                                                                <td colSpan={3}>Order Total</td>
                                                                <td style={{ fontWeight: 'bold' }}>
                                                                    {orderDetails?.orderTotal}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                    <div className="col-lg-4">
                                        <h3 className="cart-page-title" style={{ margin: '0px' }}>Order No: {orderDetails?.orderNo}</h3>
                                        <p className="cart-page-title" style={{ marginBottom: '3px' }}>Payment Status: <Badge color="peachpuff" style={{ color: 'Black' }} count={masterData.find(m => m.value === orderDetails?.paymentStatus)?.name} ></Badge></p>
                                        <p className="cart-page-title" style={{ marginBottom: '3px' }}>
                                            Payment Type: {orderDetails?.paymentType === 'O' ? 'Online Payment' : orderDetails?.paymentType === 'C' ? 'Cash On Delivery' : ''}
                                            ({orderDetails?.paymentProvider})
                                        </p>
                                        <h7 className="cart-page-title">Order Date: Placed on {moment(orderDetails?.orderDate).format(AppConsts.DATE_FORMAT_MMMM_D_COMMA_YYYY_AT_H_MM_A)}</h7>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="your-order-area">
                                            <div className="your-order-wrap gray-bg-4">
                                                <div className="your-order-product-info">
                                                    <ul style={{ listStyleType: "none" }}>
                                                        <b>SHIPPING ADDRESS</b>
                                                        <li>{orderDetails?.shippingAddress?.name}</li>
                                                        <li>{orderDetails?.shippingAddress?.addressLine} {orderDetails?.shippingAddress?.area}</li>
                                                        <li>{orderDetails?.shippingAddress?.landmark}</li>
                                                        <li>{orderDetails?.shippingAddress?.pincode} - {orderDetails?.shippingAddress?.city}</li>
                                                        <li>{orderDetails?.shippingAddress?.state}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="your-order-area">
                                            <div className="your-order-wrap gray-bg-4">
                                                <div className="your-order-product-info">
                                                    <ul style={{ listStyleType: "none" }}>
                                                        <b>BILLING ADDRESS</b>
                                                        <li>{orderDetails?.billingAddress?.name}</li>
                                                        <li>{orderDetails?.billingAddress?.addressLine} {orderDetails?.billingAddress?.area}</li>
                                                        <li>{orderDetails?.billingAddress?.landmark}</li>
                                                        <li>{orderDetails?.billingAddress?.pincode} - {orderDetails?.billingAddress?.city}</li>
                                                        <li>{orderDetails?.billingAddress?.state}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    </div>
                </LayoutOne>
            </Fragment>
        </>
    );
};

export default OrderDetails;
